import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Chip,
  CircularProgress,
  Avatar,
  Typography,
  Button,
  Box
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { useConfirm } from 'material-ui-confirm'

//? Own imports
import {
  useEvents,
  useLotesByEvent,
  useMutationCreateFreeTicket,
  useUserSearch
} from 'hooks-querys'
import {
  getGenderFull,
  getInitials,
  toLocalDateFormat,
  useDebouncedEffect
} from 'utils'
import { Evento, UserItem } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    minWidth: 200
  }
}))

const Generate = () => {
  const classes = useStyles()
  const theme = useTheme()
  const confirm = useConfirm()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento>({} as Evento)
  const [selectedUser, setSelectedUser] = useState<UserItem[]>([] as UserItem[])
  const [loteId, setLoteId] = useState<number | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [searchTextValue, setSearchTextValue] = useState('')
  const [searchTextValueToSubmit, setSearchTextValueToSubmit] = useState('')
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, false, isMobile)

  const {
    data: lotes,
    isLoading: isLoadingLotes,
    isError: isErrorLotes
  } = useLotesByEvent(selectedEvento?.id)
  const {
    data: resultUserSearch,
    isError: isErrorUserSearch,
    isLoading: isLoadingUserSearch
  } = useUserSearch(searchTextValueToSubmit)

  const mutationCreateFreeTicket = useMutationCreateFreeTicket()

  useDebouncedEffect(
    () => {
      setSearchTextValueToSubmit(searchTextValue)
    },
    500,
    [searchTextValue]
  )

  useEffect(() => {
    setSelectedEvento({} as Evento)
    setLoteId(0)
    setSelectedUser([])
  }, [estabelecimentoId])

  const handleClickSubmit = () => {
    const loteDetails = lotes?.find((lote) => lote.id === loteId)
    confirm({
      title: 'Você tem certeza que deseja gerar o ingresso?',
      confirmationButtonProps: {
        disabled: mutationCreateFreeTicket.isLoading,
        endIcon: mutationCreateFreeTicket.isLoading && (
          <CircularProgress size={18} />
        )
      },
      description: (
        <div>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Evento:{' '}
            <strong>
              {selectedEvento.nome} - (
              {toLocalDateFormat(selectedEvento.dataHoraInicio)})
            </strong>
            {'\n'}
          </Typography>
          <Typography gutterBottom variant="body1" color="textPrimary">
            Lote:{' '}
            <strong>
              {loteDetails
                ? loteDetails.tipoIngresso
                  ? `${loteDetails.tipoIngresso.descricao} - ${
                      loteDetails.descricao
                    } (${getGenderFull(loteDetails.genero)})`
                  : loteDetails.descricao
                : ''}
            </strong>
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" color="textPrimary">
              O ingresso será gerado para o seguinte usuário:
            </Typography>
            <Typography variant="caption" color="textPrimary">
              O usuário receberá uma notificação no aplicativo com o ingresso
              gerado.
            </Typography>
            <Box mt={2} className={classes.nameCell}>
              <Avatar className={classes.avatar} src={selectedUser[0]?.imagem}>
                {getInitials(selectedUser[0]?.nome)}
              </Avatar>
              <div>
                <Typography variant="body1">
                  {`${selectedUser[0]?.nome} ${
                    selectedUser[0]?.username
                      ? `(@${selectedUser[0].username})`
                      : ''
                  }`}
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
      ),
      confirmationText: (
        <>
          {mutationCreateFreeTicket.isLoading
            ? 'Gerando ingresso...'
            : 'Enviar cortesia'}
        </>
      ),
      cancellationText: 'Não!'
    }).then(() => {
      if (selectedUser[0] && selectedUser[0].id > 0 && loteId && loteId > 0) {
        mutationCreateFreeTicket
          .mutateAsync({
            usuarioId: selectedUser[0].id,
            loteId: loteId
          })
          .then(() => {
            setSelectedUser([])
            setSearchTextValue('')
          })
      }
    })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              loading={isLoadingEvents}
              id="combo-box-evento"
              options={events || []}
              value={selectedEvento}
              onChange={(event, newValue, reason) => {
                if (reason === 'select-option') {
                  newValue !== null && setSelectedEvento(newValue)
                }
                if (reason === 'clear') {
                  //? se o usuário clicar no botão limpar
                  setEventoInputValue('')
                  setSelectedEvento({} as Evento)
                }
              }}
              getOptionLabel={(evento) =>
                evento.nome &&
                `${evento.nome} ${
                  evento.dataHoraInicio
                    ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                    : ''
                }`
              }
              placeholder="Selecione o evento..."
              onInputChange={(event, newInputValue) => {
                newInputValue !== null
                  ? setEventoInputValue(newInputValue)
                  : setEventoInputValue('')
              }}
              inputValue={eventoInputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o evento"
                  placeholder="Selecione o evento..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <>
                            {isFetchingEvents && (
                              <CircularProgress
                                style={{ marginRight: 10 }}
                                size={20}
                                color="primary"
                              />
                            )}
                          </>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {selectedEvento && selectedEvento.id > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="lote-label">Selecione o lote</InputLabel>
                <Select
                  disabled={isLoadingLotes}
                  placeholder="Selecione o lote"
                  labelId="lote-label"
                  id="lote-select"
                  value={loteId}
                  onChange={(event) => {
                    setLoteId(Number(event.target.value))
                  }}
                  label="Selecione o lote"
                >
                  <MenuItem disabled={loteId === 0} key={0} value={0}>
                    {loteId === 0 ? (
                      'Selecione o lote'
                    ) : (
                      <strong>Limpar seleção</strong>
                    )}
                  </MenuItem>
                  {lotes &&
                    lotes.length > 0 &&
                    lotes.map((lote) => (
                      <MenuItem
                        disabled={lote.id === loteId}
                        key={lote.id}
                        value={lote.id}
                      >
                        {lote.tipoIngresso
                          ? `${lote.tipoIngresso.descricao} - ${
                              lote.descricao
                            } (${getGenderFull(lote.genero)})`
                          : lote.descricao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {loteId && loteId > 0 ? (
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    getOptionDisabled={(option) =>
                      selectedUser.some((user) => user.id === option.id)
                    }
                    loading={isLoadingUserSearch}
                    id="combo-box-user"
                    multiple
                    options={
                      resultUserSearch?.filter(
                        (userItem) => userItem.tipo === 'usuario'
                      ) || []
                    }
                    value={selectedUser}
                    onChange={(event, newValue, reason) => {
                      if (reason === 'select-option') {
                        newValue !== null &&
                          setSelectedUser([newValue[newValue.length - 1]])
                      }
                      if (reason === 'clear') {
                        //? se o usuário clicar no botão limpar
                        setSearchTextValue('')
                        setSelectedUser([] as UserItem[])
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === value.length - 1) {
                          return (
                            <Chip
                              {...getTagProps({ index })}
                              avatar={
                                <Avatar src={option?.imagem}>
                                  {getInitials(option?.nome)}
                                </Avatar>
                              }
                              variant="outlined"
                              label={`${option?.nome} ${
                                option.username ? `(@${option.username})` : ''
                              }`}
                              onDelete={() => {
                                setSearchTextValue('')
                                setSelectedUser([] as UserItem[])
                              }}
                            />
                          )
                        }
                      })
                    }
                    getOptionLabel={(userItem) =>
                      userItem.nome &&
                      `${userItem.nome} ${
                        userItem.username ? ` - (${userItem.username})` : ''
                      }`
                    }
                    placeholder="Buscar por nome ou nome do usuário..."
                    onInputChange={(event, newInputValue) => {
                      newInputValue !== null
                        ? setSearchTextValue(newInputValue)
                        : setSearchTextValue('')
                    }}
                    inputValue={searchTextValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o usuário"
                        placeholder="Buscar por nome ou nome do usuário..."
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                <>
                                  {isLoadingUserSearch && (
                                    <CircularProgress
                                      style={{ marginRight: 10 }}
                                      size={20}
                                      color="primary"
                                    />
                                  )}
                                </>
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    noOptionsText={
                      searchTextValueToSubmit.length > 0
                        ? 'Nenhum usuário encontrado'
                        : 'Digite para buscar'
                    }
                    renderOption={(userItem) => (
                      <div className={classes.nameCell}>
                        <Avatar
                          className={classes.avatar}
                          src={userItem?.imagem}
                        >
                          {getInitials(userItem?.nome)}
                        </Avatar>
                        <div>
                          <Typography variant="body1">
                            {`${userItem?.nome} ${
                              userItem.username ? `(@${userItem.username})` : ''
                            }`}
                          </Typography>
                        </div>
                      </div>
                    )}
                  />
                </FormControl>
              </Grid>
            ) : null}
          </>
        )}
        {selectedUser[0] && selectedUser[0].id > 0 && loteId && loteId > 0 ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={mutationCreateFreeTicket.isLoading}
              onClick={handleClickSubmit}
              className={classes.submitButton}
              endIcon={
                mutationCreateFreeTicket.isLoading && (
                  <CircularProgress size={18} />
                )
              }
            >
              {mutationCreateFreeTicket.isLoading
                ? 'Gerando ingresso...'
                : 'Gerar ingresso'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}

export default Generate

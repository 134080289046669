import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

//? Own imports
import { Ingresso } from 'types/api'
import { crudService } from '_services'

type Payload = {
  usuarioId: number
  loteId: number
}

const postCreateFreeTicket = async (payload: Payload) => {
  const { data } = await crudService.post(`ingressos/cortesia`, payload)
  return data
}

export default function useMutationCreateFreeTicket() {
  const queryClient = useQueryClient()
  return useMutation(
    'mutationCreateFreeTicket',
    (payload: Payload) => postCreateFreeTicket(payload),
    {
      onError: (error) => {
        // @ts-ignore
        toast.error(error.message || 'Erro ao gerar ingresso cortesia')
      },
      onSuccess: (data: Ingresso) => {
        queryClient.invalidateQueries(['tickets'])
        toast.success('Ingresso cortesia gerado com sucesso!')
      }
    }
  )
}

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { loteAction, tipoIngressoAction } from '_actions'

const ModalAddTipoDeIngresso = () => {
  const dispatch = useDispatch()
  const { tipoIngresso, usuarioEstabelecimento } = useSelector((state) => state)
  const open = useSelector((state) => state.lote.openModalTipoDeIngresso)

  const handleClose = () => {
    dispatch(loteAction.openModalTipoDeIngresso(false))
  }
  const handleChange = (prop) => (event) => {
    dispatch(tipoIngressoAction.onChangeProps(prop, event))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      id: 0,
      descricao: tipoIngresso.descricao,
      estabelecimentoId: usuarioEstabelecimento.estabelecimentoId
    }
    dispatch(tipoIngressoAction.createTipoIngresso(payload))
    dispatch(loteAction.openModalTipoDeIngresso(false))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Adicionar novo Tipo de Ingresso
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="textPrimary">
              Entre com a descrição do Tipo de Ingresso
            </Typography>
            <Typography variant="caption" color="textPrimary">
              Ex: Pista, Camarote, VIP... Passaporte completo, etc.
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="descricao"
            label="Descrição"
            inputProps={{
              minlength: '3'
            }}
            placeholder="Pista, Camarote, VIP... Passaporte completo, etc."
            type="text"
            value={tipoIngresso.descricao}
            onChange={handleChange('descricao')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalAddTipoDeIngresso

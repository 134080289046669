import React, { useEffect } from 'react'
import { Header, Page } from 'components'
import {
  Divider,
  makeStyles,
  Tab,
  Tabs,
  colors,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { Tickets, Summary } from './components'
import { useSelector, useDispatch } from 'react-redux'
import { AccessControl } from 'components/Utils/AccessControl'
import { movimentacaoFinanceiraAction } from '_actions'
import { Redirect, useParams } from 'react-router'
import { history } from 'utils'
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3, 2, 2, 2)
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const MovimentacoesFinanceirasDetails = () => {
  const classes = useStyles()
  const { id, tab } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const handleTabsChange = (event, value) => {
    history.push(value)
  }
  const movimentacaoFinanceira = useSelector(
    (state) => state.movimentacaoFinanceira
  )
  const { isLoading } = useSelector((state) => state.movimentacaoFinanceira)

  useEffect(() => {
    dispatch(movimentacaoFinanceiraAction.getMovimentacaoFinanceiraById(id))
  }, [])

  if (!id) {
    return null
  }
  const tabs = [
    {
      value: 'resumo',
      label: 'Resumo',
      icon: <MenuBookTwoToneIcon />
    },
    {
      value: 'ingressos',
      label: 'Ingressos',
      icon: <ConfirmationNumberTwoToneIcon />
    }
  ]

  if (!tab) {
    return <Redirect to={`movimentacaoFinanceira/${id}/resumo`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <AccessControl
      rule={'movimentacoesFinanceiras.list'} //permissão necessária para acessar conteúdo
      yes={() => (
        <Page
          className={classes.root}
          title="Detalhes de movimentação financeira"
        >
          <Header
            className={classes.header}
            subtitle="Gerência de movimentações financeiras"
            title={`Detalhes de movimentação financeira N.º: ${id}`}
          />
          <Tabs
            className={classes.tabs}
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant={isMobile ? 'fullWidth' : 'scrollable'}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={tab.icon}
              />
            ))}
          </Tabs>
          <Divider className={classes.divider} />
          <div className={classes.content}>
            {tab === 'resumo' && (
              <Summary movimentacaoFinanceira={movimentacaoFinanceira} />
            )}
            {tab === 'ingressos' && (
              <Tickets movimentacaoFinanceiraId={movimentacaoFinanceira.id} />
            )}
          </div>
        </Page>
      )}
    />
  )
}

export default MovimentacoesFinanceirasDetails

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { history } from 'utils'
import {
  Button,
  Dialog,
  Hidden,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  Select,
  Typography,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import BusinessIcon from '@material-ui/icons/Business'
import { useDispatch, useSelector, connect } from 'react-redux'
import { usuarioEstabelecimentoAction } from '_actions'
import { estabelecimentoAction } from '_actions'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  }
}))

const SelectEstabelecimento = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const { usuarioEstabelecimento, estabelecimento } = useSelector(
    (state) => state.usuarioEstabelecimento
  )
  const isAddPlace = useSelector((state) => {
    return state.usuarioEstabelecimento.isAddPlace
  })

  const hasEstabelecimentoSelected = useSelector((state) => {
    if (
      state.usuarioEstabelecimento.estabelecimentoId === '' ||
      state.usuarioEstabelecimento.estabelecimentoId === undefined
    ) {
      return false
    }
    return true
  })

  const dispatch = useDispatch()

  const emailVerificado = useSelector(
    (state) => state.authentication.user.emailVerificado
  )

  useEffect(() => {
    setInitialUsuarioEstabelecimento()
  }, [usuarioEstabelecimento])

  const handleChange = (event) => {
    let selectedUsuarioEstabelecimento = usuarioEstabelecimento.filter(
      (item) => item.estabelecimentoId === event.target.value
    )
    dispatch(
      usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
        selectedUsuarioEstabelecimento[0]
      )
    )
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true)
    if (undefined !== usuarioEstabelecimento && usuarioEstabelecimento.length) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const setInitialUsuarioEstabelecimento = () => {
    if (
      usuarioEstabelecimento.length === 0 ||
      usuarioEstabelecimento === undefined
    ) {
      return null
    } else if (
      undefined !== usuarioEstabelecimento &&
      usuarioEstabelecimento.length === 1
    ) {
      dispatch(
        usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
          usuarioEstabelecimento[0]
        )
      )
    } else if (!hasEstabelecimentoSelected) {
      setOpen(true)
      setDisabled(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDisabled(false)
  }

  const getUsuarioEstabelecimentoId = useSelector((state) => {
    return state.usuarioEstabelecimento.id
  })

  const getBackupIndex = useSelector((state) => {
    let indexBackup = state.usuarioEstabelecimento.usuarioEstabelecimento.findIndex(
      (item) => item.id === state.usuarioEstabelecimento.backupId
    )
    return indexBackup
  })

  const handleAddPlace = () => {
    setOpen(false)
    if (!isAddPlace) {
      dispatch(
        usuarioEstabelecimentoAction.setIsAddPlace(
          true,
          getUsuarioEstabelecimentoId
        )
      )
      dispatch(estabelecimentoAction.clearFields())
      history.push('/estabelecimento/general')
    } else {
      dispatch(
        usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
          usuarioEstabelecimento[getBackupIndex]
        )
      )
      dispatch(usuarioEstabelecimentoAction.setIsAddPlace(false, ''))
    }
  }

  const returnContent = () => {
    return (
      <div>
        <Hidden smDown>
          <Typography variant="overline" color="inherit">
            Estabelecimento selecionado:
            <Typography variant="button" color="inherit">
              {' '}
              <b>{estabelecimento.nome}</b>
            </Typography>
          </Typography>
        </Hidden>
        <Tooltip title="Trocar estabelecimento">
          <IconButton color="inherit" onClick={handleClickOpen}>
            <Hidden mdUp>
              <BusinessIcon />
            </Hidden>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            {' '}
            <Typography variant="overline" color="inherit">
              meus estabelecimentos
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel id="estabelecimento-select-label">
                  VER ESTABELECIMENTOS
                </InputLabel>
                <Select
                  labelId="estabelecimento-select-label"
                  id="estabelecimento-select"
                  value={usuarioEstabelecimento.estabelecimentoId}
                  onChange={handleChange}
                  input={<Input />}
                  disabled={isAddPlace}
                >
                  <MenuItem disabled value="">
                    <em>Meus Estabelecimentos</em>
                  </MenuItem>
                  {usuarioEstabelecimento.map((row) => (
                    <MenuItem
                      key={row.estabelecimento.id}
                      estabelecimento={row.estabelecimento}
                      value={row.estabelecimentoId}
                      disabled={
                        row.estabelecimentoId === estabelecimento.id
                          ? true
                          : false
                      }
                    >
                      {row.estabelecimento.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button disabled={disabled} onClick={handleClose} color="primary">
              Cancelar
            </Button>
            {/* <Button onClick={handleClose} color="primary">
              Selecionar
        </Button> */}
            <Button
              disabled={disabled}
              onClick={handleAddPlace}
              color="primary"
              variant="contained"
            >
              {isAddPlace ? 'Cancelar Adição' : 'Adicionar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  return (
    <div>
      {usuarioEstabelecimento.length === 0 ||
        usuarioEstabelecimento === undefined
        ? emailVerificado && (
          <Hidden smDown>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => history.push(`/estabelecimento/`)}
            >
              Completar cadastro
            </Button>
          </Hidden>
        )
        : usuarioEstabelecimento.length === 1
          ? null
          : returnContent()}
    </div>
  )
}

SelectEstabelecimento.propTypes = {
  className: PropTypes.string
}
export default SelectEstabelecimento

import React, { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LocationCityRoundedIcon from '@material-ui/icons/LocationCityRounded'
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded'
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded'
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  withStyles,
  Button,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Page } from 'components'
import { Header, General, Location, Usuarios, Level } from './components'
import { estabelecimentoAction } from '../../../_actions'
import { usuarioEstabelecimentoAction } from '_actions'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {},
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  bottomLeftBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
})

const EstabelecimentoEdit = (props) => {
  const { match, history } = props
  const { classes } = props
  const { tab } = match.params
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isAddPlace = useSelector((state) => {
    return state.usuarioEstabelecimento.isAddPlace
  })
  const { usuarioEstabelecimento } = useSelector(
    (state) => state.usuarioEstabelecimento
  )

  const hasEstabelecimentoSelected = useSelector((state) => {
    if (
      state.usuarioEstabelecimento.estabelecimentoId === '' ||
      state.usuarioEstabelecimento.estabelecimentoId === undefined
    ) {
      return false
    }
    return true
  })

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const getUsuarioEstabelecimentoId = useSelector((state) => {
    return state.usuarioEstabelecimento.id
  })

  const getBackupIndex = useSelector((state) => {
    let indexBackup = state.usuarioEstabelecimento.usuarioEstabelecimento.findIndex(
      (item) => item.id === state.usuarioEstabelecimento.backupId
    )
    return indexBackup
  })

  const handleAddPlace = () => {
    if (!isAddPlace) {
      dispatch(
        usuarioEstabelecimentoAction.setIsAddPlace(
          true,
          getUsuarioEstabelecimentoId
        )
      )
      dispatch(estabelecimentoAction.clearFields())
      history.push('/estabelecimento/general')
    } else {
      dispatch(
        usuarioEstabelecimentoAction.changeDetailsUsuarioEstabelecimento(
          usuarioEstabelecimento[getBackupIndex]
        )
      )
      dispatch(usuarioEstabelecimentoAction.setIsAddPlace(false, ''))
    }
  }

  const tabs = []
  {
    //Adicionar outro estabelecimento
    isAddPlace &&
      tabs.push({
        value: 'general',
        label: 'Adicionar',
        icon: <LocationCityRoundedIcon />
      })
  }
  {
    //Primeiro estabelecimento ou edição
    !isAddPlace &&
      tabs.push({
        value: 'general',
        label: 'Geral',
        icon: <LocationCityRoundedIcon />
      })
  }
  {
    //Definir melhor a localização via maps
    //isAddPlace &&
    tabs.push({
      disabled: !hasEstabelecimentoSelected || isAddPlace,
      value: 'location',
      label: 'Localização',
      icon: <LocationOnRoundedIcon />
    })
  }
  {
    //Tab de Usuários
    hasEstabelecimentoSelected &&
      !isAddPlace &&
      tabs.push({
        value: 'users',
        label: 'Usuários ',
        icon: <PeopleOutlineRoundedIcon />
      })
  }
  /*{
    //Level Tab
    hasEstabelecimentoSelected &&
      !isAddPlace &&
      tabs.push({
        value: 'level',
        label: 'Nível ',
        icon: <TrendingUpRoundedIcon />
      })
  }*/

  if (!tab) {
    return <Redirect to={`/estabelecimento/general`} />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <>
      <Header />
      <Page
        className={classes.root}
        // title="EstabelecimentoEdit"
      >
        <>
          {hasEstabelecimentoSelected && (
            <Box component="span" className={classes.box}>
              <Button
                fullWidth={isMobile}
                onClick={handleAddPlace}
                color="primary"
                variant="contained"
              >
                {!isAddPlace
                  ? 'Adicionar Outro Estabelecimento'
                  : 'Cancelar Adição'}
              </Button>
            </Box>
          )}
        </>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant={'scrollable'}
        >
          {tabs.map((tab) => (
            <Tab
              disabled={tab.disabled}
              key={tab.value}
              label={tab.label}
              value={tab.value}
              icon={tab.icon}
            />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === 'general' && <General />}
          {tab === 'location' && <Location />}
          {tab === 'users' && <Usuarios />}
          {tab === 'level' && <Level />}
        </div>
      </Page>
    </>
  )
}

EstabelecimentoEdit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    estabelecimento: state.estabelecimento,
    usuarioEstabelecimento: state.usuarioEstabelecimento
  }
}
const connectedEstabelecimentoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EstabelecimentoEdit))
)
export { connectedEstabelecimentoEditPage as EstabelecimentoEdit }
